/*
* ----------------------------------------------------------------------------------------
Author       : Themefisher
Template Name: Vixcon- Event Template
Version      : 1.0
* ----------------------------------------------------------------------------------------
*/
/*
* ----------------------------------------------------------------------------------------
* 01.GLOBAL CSS STYLE
* 02.TYPOGRAPHY CSS STYLE
* 03.HOMEPAGE CSS STYLE
    -LOGO CSS
    -MENU CSS
* 04.ABOUT CSS STYLE
* 05.SERVICE CSS STYLE
* 06.WORK CSS STYLE
* 07.AVAILABLE CSS STYLE
* 08.CONTACT CSS STYLE
* 09.FOOTER CSS STYLE
* ----------------------------------------------------------------------------------------
*/
/*=== MEDIA QUERY ===*/
/*-------------------------------
typography
-------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800&display=swap");
body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  color: #444444;
}

body, html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

a {
  color: #111111;
  transition: .3s all;
}

a:hover {
  color: #ff007a;
}

a,
a:hover,
button,
button:focus {
  transition: all .3s;
  text-decoration: none;
  outline: 0;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 700;
  color: #111111;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
}

h1, .h1 {
  font-size: 48px;
}

h2, .h2 {
  font-size: 36px;
}

h3, .h3 {
  font-size: 26px;
}

h4, .h4 {
  font-size: 22px;
}

h5, .h5 {
  font-size: 18px;
}

h6, .h6 {
  font-size: 16px;
}

p {
  line-height: 28px;
}

.lead {
  line-height: 35px;
}

.text-secondary {
  color: #4125dd !important;
}

.text-primary {
  color: #ff007a !important;
}

.subtitle {
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
}

.lg-title {
  font-size: 62px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/*-------------------------------
Common
-------------------------------*/
.section {
  padding: 90px 0px;
}

.section-sm {
  padding: 90px 0px;
}

.section-top {
  padding-top: 90px;
}

.section-bottom {
  padding-bottom: 90px;
}

.mb-30 {
  margin-bottom: 30px;
}

.section-heading {
  margin-bottom: 75px;
}

.section-heading p {
  margin-top: 20px;
}

.form-control {
  border-color: #e8e8e8;
  height: 50px;
  padding-left: 20px;
  font-size: 13px;
  background: #eee;
}

.form-control:focus,
.form-control:hover,
.form-control:active {
  box-shadow: none;
  border-color: #ff007a;
}

.bg-secondary {
  background: #4125dd !important;
}

.bg-primary {
  background: #ff007a !important;
}

.content-padding {
  padding: 220px 0px 200px 0px;
}

.btn {
  line-height: 1;
  padding: 16px 32px;
  box-shadow: 0 11px 22px rgba(34, 34, 34, 0.2);
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  will-change: box-shadow;
  font-size: 13px;
  border-radius: 0px;
  letter-spacing: .2em;
  text-transform: uppercase;
  border: 2px solid transparent;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.btn-rounded {
  border-radius: 60px;
}

.btn-hero {
  background: #ff007a;
  color: #ffffff;
  border-color: #ff007a;
}

.btn-hero:hover {
  background: #111111;
  border-color: #111111;
  color: #ffffff;
}

.btn-secondary {
  background: #4125dd;
  border-color: #4125dd;
  color: #ffffff;
}

.btn-secondary:hover {
  background: transparent;
  color: #111111;
  border-color: #4125dd;
}

.btn-gray {
  background: #f8f8f8;
  color: #111111;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.text-black {
  color: #111111;
}

.text-color {
  color: #ff007a;
}

.text-md {
  font-size: 45px;
  line-height: 1.2;
}

.box-shadow {
  box-shadow: 0 15px 38px 0 rgba(0, 0, 0, 0.1);
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.06) !important;
}

.overflow-hidden {
  overflow: hidden;
}

a {
  color: inherit;
}

.title strong {
  color: #ff007a;
}

/*-------------------------------
header
-------------------------------*/
.navbar-brand {
  font-size: 28px;
  font-weight: 700;
  color: #4125dd;
}

.main-nav {
  transition: all .7s linear;
}

.main-nav .nav-link {
  font-size: 14px;
  padding: 26px 18px !important;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #111111;
  opacity: .9;
}

.btn-sm {
  padding: 5px 18px;
  border-radius: 50px;
}

@media (min-width: 768px) {
  .top-nav-collapse {
    text-shadow: none;
    border-color: transparent;
    background-color: #ffffff;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease;
  }
}

.dropdown {
  padding: 0px;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  padding: 10px 12px 10px 25px;
  border-bottom: 1px dashed #e8e8e8;
  border-left: 3px solid transparent;
}

.dropdown-item:hover {
  color: #ff007a;
  background: transparent;
  border-left: 3px solid #ff007a;
}

.dropdown-menu {
  min-width: 250px;
  border: 0px;
  border-radius: 0px;
  box-shadow: 3px 1px 20px 0 rgba(0, 0, 0, 0.07);
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  transition: all .15s ease-out;
  display: block;
  padding: 0px !important;
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.page-header ul li {
  color: #ffffff;
  font-size: 18px;
}

.page-header ul li a {
  color: #ffffff;
}

.page-header .divider {
  width: 100px;
  height: 4px;
}

.error h2 {
  font-size: 89px;
  color: #888;
}

@media (max-width: 480px) {
  .main-nav .nav-link {
    padding: 10px 18px !important;
  }
  .navbar-nav .dropdown-menu {
    position: absolute;
  }
}

@media (max-width: 400px) {
  .main-nav .nav-link {
    padding: 10px 18px !important;
  }
  .navbar-nav .dropdown-menu {
    position: absolute;
  }
}

@media (max-width: 767px) {
  .main-nav .nav-link {
    padding: 10px 18px !important;
  }
  .navbar-nav .dropdown-menu {
    position: absolute;
  }
}

@media (max-width: 991px) {
  .main-nav .nav-link {
    padding: 10px 18px !important;
  }
  .navbar-nav .dropdown-menu {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 991px) {
  #select-language {
    display: block;
    margin: auto !important;
  }
}

/*-------------------------------
banner
-------------------------------*/
.section-banner {
  min-height: 700px;
  position: relative;
  background: #eee;
  background-repeat: no-repeat;
  background-position: 65% 50%;
  background-size: cover;
}

.section-banner .banner-content h1 {
  font-size: 100px;
  line-height: 1.2;
  letter-spacing: -.04em;
}

@media (max-width: 991px) {
  .section-banner .banner-content h1 {
    font-size: 80px;
  }
}

@media (max-width: 767px) {
  .section-banner .banner-content h1 {
    font-size: 60px;
  }
}

.section-banner .banner-content span {
  color: #444;
  font-size: 18px;
}

.banner-img {
  position: absolute;
  right: -160px;
  top: 0px;
}

.divider {
  width: 120px;
  height: 6px;
  background: #ff007a;
  display: block;
}

@media (max-width: 480px) {
  .section-banner {
    min-height: 500px;
  }
}

@media (max-width: 400px) {
  .section-banner {
    min-height: 400px;
  }
}

@media (max-width: 767px) {
  .section-banner {
    min-height: 515px;
  }
}

/*-------------------------------
About
-------------------------------*/
.about-img {
  box-shadow: 0 15px 38px 0 rgba(0, 0, 0, 0.1);
}

.about-img .img-block {
  position: absolute;
  content: "";
  bottom: -70px;
  right: 0px;
  left: 30%;
  width: 100%;
  border: 10px solid #ffffff;
}

@media (max-width: 991px) {
  .about-img .img-block {
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    bottom: -20px;
  }
}

.stroke-text {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ddd;
  font-size: 120px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
}

@media (max-width: 767px) {
  .stroke-text {
    font-size: 90px;
  }
}

@media (max-width: 480px) {
  .stroke-text {
    font-size: 50px;
  }
}

.text-lg {
  font-size: 52px;
  line-height: 1.1;
}

.ml-90 {
  margin-left: 160px;
}

@media (max-width: 991px) {
  .ml-90 {
    margin-left: 0;
  }
}

/*-------------------------------
inner pages
-------------------------------*/
.page-header {
  background: url("../images/bg/cta-bg.jpg") no-repeat;
  background-size: cover;
  padding: 80px 0px;
  position: relative;
}

.overly {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #4125dd;
  opacity: .90;
}

#faqs h4 {
  color: #444;
}

.text-sm-2 {
  font-size: 16px;
}

.socials-links a {
  font-size: 18px;
  padding: 5px 10px 0px 0px;
}

#map {
  min-height: 550px;
}

.google-map {
  position: relative;
}

.contact-info-block {
  position: absolute;
  content: "";
  left: 30px;
  top: 30px;
  z-index: 1;
  background: #ffffff;
}

@media (max-width: 480px) {
  .contact-info-block {
    position: static;
  }
}

@media (max-width: 480px) {
  .latest-blog {
    padding-top: 100px;
  }
}

@media (max-width: 400px) {
  .latest-blog {
    padding-top: 100px;
  }
}

@media (max-width: 767px) {
  .latest-blog {
    padding-top: 100px;
  }
}

/*-------------------------------
Features
-------------------------------*/
.feature-box {
  padding: 40px;
  background: #f8f9fa;
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  -ms-transition: all .35s ease;
  -o-transition: all .35s ease;
  transition: all .35s ease;
}

.feature-box i {
  font-size: 60px;
}

.feature-box .divider {
  width: 45px;
  margin-bottom: 20px;
  background: #4125dd;
}

.feature-box:hover {
  background: #4125dd;
}

.feature-box:hover i {
  color: #ffffff;
}

.feature-box:hover h4 {
  color: #ffffff;
}

.feature-box:hover p {
  color: rgba(255, 255, 255, 0.8);
}

.feature-box:hover .divider {
  background: #e8e8e8;
}

.feature-box.active {
  background: #4125dd;
}

.feature-box.active i {
  color: #ffffff;
}

.feature-box.active h4 {
  color: #ffffff;
}

.feature-box.active p {
  color: rgba(255, 255, 255, 0.8);
}

.feature-box.active .divider {
  background: #e8e8e8;
}

.pl-90 {
  padding-left: 150px;
}

.heading h2 {
  position: relative;
}

.heading h2:before {
  position: absolute;
  content: "";
  left: -130px;
  top: 20px;
  width: 100px;
  height: 3px;
  background: #ff007a;
}

.bg-red {
  background: #f87c57;
}

.feature-item {
  padding: 40px;
  background: #f8f9fa;
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  -ms-transition: all .35s ease;
  -o-transition: all .35s ease;
  transition: all .35s ease;
}

.feature-item i {
  font-size: 60px;
  color: #ffffff;
}

.feature-item h4 {
  color: #ffffff;
}

.feature-item p {
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 480px) {
  .pl-90 {
    padding-left: 0px;
  }
}

@media (max-width: 400px) {
  .pl-90 {
    padding-left: 0px;
  }
}

@media (max-width: 767px) {
  .pl-90 {
    padding-left: 0px;
  }
}

/*-------------------------------
Features
-------------------------------*/
.cta {
  position: relative;
}

.cta:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 50%;
  height: 70%;
  background: url("../images/about/bg-1.jpg");
  background-size: cover;
}

.cta .cta-content {
  border: 1px solid #e8e8e8;
}

.cta-img {
  position: relative;
}

.cta-img .contact-info {
  position: absolute;
  content: "";
  right: 40px;
  bottom: -90px;
  background: #ffffff;
  padding: 60px 50px;
}

@media (max-width: 767px) {
  .cta-img .contact-info {
    position: static;
  }
}

.sub-form {
  position: relative;
}

.sub-form .form-control {
  border-color: #4125dd;
  height: 51px;
}

.sub-form .btn {
  position: absolute;
  top: 0px;
  right: 0;
  margin-top: 0px !important;
  display: inline-block;
  padding: 17px 35px;
  border-radius: 0px 5px 5px 0px;
}

.sub-form .btn:hover {
  background: #111111;
  color: #ffffff;
}

/*-------------------------------
Team
-------------------------------*/
.speaker-block .img-block {
  position: relative;
}

.speaker-block .img-block:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 70%;
  background: rgba(59, 29, 130, 0.5);
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  opacity: 0;
}

.speaker-block .img-block:hover:before {
  opacity: 1;
  height: 100%;
}

.speaker-block .img-block:hover .speaker-social {
  opacity: 1;
}

.speaker-block img {
  border-radius: 5px;
}

.speaker-block .speaker-social {
  position: absolute;
  content: "";
  bottom: 10%;
  right: 0px;
  left: 40%;
  margin-left: -50px;
  opacity: 0;
  margin-top: -50px;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
}

.speaker-block .speaker-social li a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #4125dd;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  font-size: 18px;
  border-radius: 5px;
  box-shadow: 0 15px 38px 0 rgba(0, 0, 0, 0.1);
}

.speaker-block .speaker-social li a:hover {
  background: #ff007a;
}

@media (max-width: 767px) {
  .speaker-block .speaker-social li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.speaker-block.style-2 .img-block {
  position: relative;
}

.speaker-block.style-2 .img-block:before {
  display: none;
}

.speaker-block.style-2 .img-block:hover .speaker-social {
  opacity: 1;
}

.speaker-single-wrap p {
  font-size: 16px;
  line-height: 2;
}

.social-single a {
  margin-right: 15px;
  font-size: 18px;
}

.skill-item i {
  font-size: 40px;
  display: inline-block;
  margin-bottom: 15px;
  color: #ff007a;
}

/*-------------------------------
Schedule
-------------------------------*/
#TopicTab {
  background: #eee;
  border-radius: 5px;
}

.nav-justified .nav-item {
  text-align: left !important;
}

.nav-pills .nav-link {
  border-radius: 0px;
  padding: 20px 30px;
  -webkit-transition: all .45s ease;
  -moz-transition: all .45s ease;
  -ms-transition: all .45s ease;
  -o-transition: all .45s ease;
  transition: all .45s ease;
}

.nav-pills .nav-link.active {
  background: #ff007a;
  border-radius: 8px;
  position: relative;
}

.nav-pills .nav-link.active h2 {
  color: #ffffff;
}

.nav-pills .nav-link.active h5 {
  color: #ffffff;
}

.nav-pills .nav-link.active:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 17px 20px 0;
  border-color: #000 transparent transparent;
  content: "";
  position: absolute;
  bottom: -18px;
  transform: translateY(-50%);
  transition: .3s;
  left: 80px;
  margin: auto;
  z-index: -1;
  border-color: #ff007a transparent transparent;
}

.time-table .time {
  flex-basis: 20%;
}

.time-table .content {
  flex-basis: 60%;
}

.time-table .content-img {
  flex-basis: 20%;
}

.time-table .content-img img {
  border-radius: 100%;
}

@media screen {
  .time-table .content-img img {
    width: 100px;
  }
}

.time-table li {
  box-shadow: 0 15px 38px 0 rgba(0, 0, 0, 0.1);
}

.text-color-2 {
  color: #4125dd;
}

/*-------------------------------
Pricing
-------------------------------*/
.package {
  text-align: center;
  box-shadow: 0 11px 22px rgba(34, 34, 34, 0.2);
}

.package .price-header {
  padding: 60px 0px 60px 0px;
  background: url("../images/bg/page-header.jpg") no-repeat;
  background-size: cover;
  position: relative;
}

.package ul li {
  padding: 5px 0px;
  color: #111111;
}

.package .pname {
  margin: -45px auto 30px;
  width: 50%;
  padding: 10px;
  border-radius: 25px;
  font-weight: 500;
  border: 1px solid #eee;
  text-transform: capitalize;
  background: #ffffff;
  position: relative;
  z-index: 1;
}

.package i {
  font-size: 60px;
  color: #ffffff;
}

.bg-orange {
  background: #ff4747;
}

.style-1 .pname {
  border-color: #ff007a;
}

.style-2 .pname {
  border-color: #ff4747;
}

.style-2 .btn {
  border-color: #ff4747;
  background: #ff4747;
  color: #ffffff;
}

.style-3 .pname {
  border-color: #4125dd;
}

.section-pricing {
  background: url("../images/banner/speakers-bg-black.jpg") no-repeat;
  background-size: cover;
}

/*-------------------------------
Sponsors
-------------------------------*/
.sponsors {
  position: relative;
  background: url("../images/bg/cta-bg.jpg") no-repeat fixed 50% 50%;
  background-size: cover;
}

.sponsors:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.97);
}

.client-item {
  float: left;
  width: 15%;
  height: 115px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  padding: 20px;
  text-align: center;
  opacity: .7;
  -webkit-transition: all .45s ease-in-out;
  -moz-transition: all .45s ease-in-out;
  -ms-transition: all .45s ease-in-out;
  -o-transition: all .45s ease-in-out;
  transition: all .45s ease-in-out;
}

.client-item a {
  display: block;
}

.client-item:hover {
  opacity: 1;
  background: #111111;
}

@media (max-width: 991px) {
  .client-item {
    width: 20%;
  }
}

@media (max-width: 767px) {
  .client-item {
    width: 30%;
  }
}

@media (max-width: 480px) {
  .client-item {
    width: 40%;
  }
}

@media (max-width: 400px) {
  .client-item {
    width: 50%;
  }
}

.bg-black {
  background: #000;
}

.letter-spacing {
  letter-spacing: 2px;
  text-transform: uppercase;
}

.text-sm {
  font-size: 14px;
}

.bg-gray {
  background: #eee;
}

/*-------------------------------
Countdown
-------------------------------*/
.counter-wrapper {
  margin-top: -70px;
}

.counter-inner {
  padding: 50px;
  position: relative;
}

.counter-inner:before {
  position: absolute;
  content: "";
  left: -5%;
  top: 0;
  width: 200%;
  height: 100%;
  background: #ffffff;
  border-radius: 100px 0px 0px 100px;
  box-shadow: 0 15px 38px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .counter-inner .counter-stat h2 {
    font-size: 20px;
  }
}

.counter-inner .syotimer {
  position: relative;
  z-index: 1;
}

@media (max-width: 991px) {
  .counter-inner {
    padding: 50px 20px;
  }
}

.font-weight-light {
  font-weight: 300;
}

.syotimer.large .syotimer-cell {
  height: 100px;
  width: 100px;
}

.syotimer-cell {
  display: inline-block;
  height: 100px;
  width: 115px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding-top: 15px;
  margin-right: 10px;
}

@media (max-width: 991px) {
  .syotimer-cell {
    width: 100px;
  }
}

@media (max-width: 480px) {
  .syotimer-cell {
    width: 65px;
    height: 70px;
  }
}

.syotimer-cell__unit {
  text-transform: capitalize;
  color: #a4a4a4;
}

.syotimer-cell__value {
  font-weight: 800;
  font-size: 45px;
  line-height: 45px;
  color: #111111;
}

@media (max-width: 480px) {
  .syotimer-cell__value {
    font-size: 30px;
    line-height: 30px;
  }
}

.syotimer-cell_type_day .syotimer-cell__value {
  color: #ff007a;
}

/*-------------------------------
Blog
-------------------------------*/
.blog-article {
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  -ms-transition: all .35s ease;
  -o-transition: all .35s ease;
  transition: all .35s ease;
}

.blog-article .read-more i {
  width: 30px;
  height: 30px;
  background: #eee;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  padding-top: 7px;
  color: #111111;
}

.blog-article img {
  width: 100%;
}

.blog-nav a {
  border: 1px solid #e8e8e8;
  display: inline-block;
  padding: 8px 25px;
  border-radius: 25px;
  font-weight: 600;
  background: #e8e8e8;
}

.widget .widget-title {
  padding-bottom: 10px;
  position: relative;
}

.widget .widget-title:after {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 0px;
  border-bottom: 2px solid #4125dd;
  width: 30px;
}

.widget.list-item li {
  padding: 15px 0px;
  font-size: 16px;
  border-bottom: 1px dashed #e8e8e8;
}

.tags a {
  display: inline-block;
  padding: 6px 15px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.post-author-info img {
  max-width: 15%;
  border-radius: 3px;
}

.comment-respond {
  border: 20px solid #e8e8e8;
}

.comment-reply-link {
  color: #777;
}

@media (max-width: 480px) {
  .comment-respond {
    border: 0px;
  }
  .media {
    display: block;
  }
  .media img {
    margin-bottom: 10px;
  }
}

@media (max-width: 400px) {
  .comment-respond {
    border: 0px;
  }
  .media {
    display: block;
  }
  .media img {
    margin-bottom: 10px;
  }
}

.social-links li a {
  width: 40px;
  height: 40px;
  text-align: center;
  background: #eee;
  display: inline-block;
  border-radius: 3px;
  padding-top: 8px;
}

.social-links li a:hover {
  background: #ff007a;
  color: #ffffff;
}

/*-------------------------------
footer
-------------------------------*/
.footer {
  background: url("../images/bg/footer-bg.jpg") no-repeat;
  background-image: cover;
  padding-bottom: 35px;
}

.footer-socails a {
  padding: 0px 10px;
}

.footer-socails a i {
  font-size: 30px;
  color: #ffffff;
}

.copy.border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.04) !important;
}

@media (max-width: 480px) {
  .sub-form .btn {
    position: relative;
    margin-top: 16px !important;
    border-radius: 5px;
  }
}

@media (max-width: 400px) {
  .sub-form .btn {
    position: relative;
    margin-top: 16px !important;
    border-radius: 5px;
  }
}

@media (max-width: 767px) {
  .sub-form .btn {
    position: relative;
    margin-top: 16px !important;
    border-radius: 5px;
  }
}

/*-------------------------------
Responsive
-------------------------------*/


/*
* ----------------------------------------------------------------------------------------
Author       : Themefisher
Template Name: Vixcon- Event Template
Version      : 1.0
* ----------------------------------------------------------------------------------------
*/

/*
* ----------------------------------------------------------------------------------------
* 01.GLOBAL CSS STYLE
* 02.TYPOGRAPHY CSS STYLE
* 03.HOMEPAGE CSS STYLE
    -LOGO CSS
    -MENU CSS
* 04.ABOUT CSS STYLE
* 05.SERVICE CSS STYLE
* 06.WORK CSS STYLE
* 07.AVAILABLE CSS STYLE
* 08.CONTACT CSS STYLE
* 09.FOOTER CSS STYLE
* ----------------------------------------------------------------------------------------
*/

@import "variables.scss";
@import 'mixins.scss';
@import 'media-query.scss';

/*-------------------------------
typography
-------------------------------*/
@import "types.scss";



/*-------------------------------
Common
-------------------------------*/
@import "common.scss";

/*-------------------------------
header
-------------------------------*/
@import "header.scss";



/*-------------------------------
banner
-------------------------------*/
@import "banner.scss";


/*-------------------------------
About
-------------------------------*/
@import "about.scss";

 

/*-------------------------------
inner pages
-------------------------------*/
@import "inner-pages.scss";


/*-------------------------------
Features
-------------------------------*/
@import "feature.scss";

/*-------------------------------
Features
-------------------------------*/
@import "call-to-action";

/*-------------------------------
Team
-------------------------------*/
@import "speaker.scss";


/*-------------------------------
Schedule
-------------------------------*/
@import "schdule.scss";


/*-------------------------------
Pricing
-------------------------------*/
@import "pricing.scss";


/*-------------------------------
Sponsors
-------------------------------*/
@import "sponsor.scss";

/*-------------------------------
Countdown
-------------------------------*/
@import "countdown.scss";


/*-------------------------------
Blog
-------------------------------*/
@import "blog.scss";

/*-------------------------------
footer
-------------------------------*/
@import "footer.scss";


/*-------------------------------
Responsive
-------------------------------*/
@import "responsive.scss";



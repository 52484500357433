.section {
  padding: 90px 0px;
}

.section-sm {
  padding: 90px 0px;
}

.section-top {
  padding-top: 90px;
}

.section-bottom {
  padding-bottom: 90px;
}

.mb-30 {
  margin-bottom: 30px;
}

.section-heading {
  margin-bottom: 75px;

  p {
    margin-top: 20px;
  }
}


.form-control {
  border-color: $border-color;
  height: 50px;
  padding-left: 20px;
  font-size: 13px;
  background: #eee;
}

.form-control:focus,
.form-control:hover,
.form-control:active {
  box-shadow: none;
  border-color: $primary-color;
}


.bg-secondary {
  background: $secondary-color !important;
}


.bg-primary {
  background: $primary-color !important;
}

.content-padding {
  padding: 220px 0px 200px 0px;
}

.btn {
  line-height: 1;
  padding: 16px 32px;
  box-shadow: 0 11px 22px rgba(34, 34, 34, .2);
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  will-change: box-shadow;
  font-size: 13px;
  border-radius: 0px;
  letter-spacing: .2em;
  text-transform: uppercase;
  border: 2px solid transparent;
  font-weight: 700;
  font-family: $base-font;
}



.btn-rounded {
  border-radius: 60px;
}

.btn-hero {
  background: $primary-color;
  color: $white;
  border-color: $primary-color;

  &:hover {
    background: $black;
    border-color: $black;
    color: $white;
  }
}



.btn-secondary {
  background: $secondary-color;
  border-color: $secondary-color;
  color: $white;

  &:hover {
    background: transparent;
    color: $black;
    border-color: $secondary-color;
  }
}


.btn-gray {
  background: $gray-light;
  color: $black;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.text-black {
  color: $black;
}

.text-color {
  color: $primary-color;
}

.text-md {
  font-size: 45px;
  line-height: 1.2;
}

.box-shadow {
  box-shadow: 0 15px 38px 0 rgba(0, 0, 0, 0.1);
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.06) !important;
}

.overflow-hidden {
  overflow: hidden;
}

a {
  color: inherit;
}

.title {
  strong {
    color: $primary-color;
  }
}
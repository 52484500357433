.package{
	text-align: center;
	box-shadow: 0 11px 22px rgba(34,34,34,.2);

	.price-header{
		padding: 60px 0px 60px 0px;
		background: url("../images/bg/page-header.jpg")no-repeat;
		background-size: cover;
		position: relative;
	}

	ul {
		li{
			padding: 5px 0px;
			color: $black;
		}
	}

	.pname{
		margin: -45px auto 30px;
		width: 50%;
		padding: 10px;
		border-radius: 25px;
		font-weight: 500;
		border: 1px solid #eee;
		text-transform: capitalize;
		background: $white;
		position: relative;
		z-index: 1;
	}
	i{
		font-size:60px;
		color:$white;
	}
}

.bg-orange{
	background: $orange;
}

.style-1{
	.pname{
		border-color:$primary-color;
	}
}

.style-2{
	.pname{
		border-color:$orange;
	}

	.btn{
		border-color:$orange;
		background: $orange;
		color: $white;
	}
}
.style-3{
	.pname{
		border-color:$secondary-color;
	}
}


// Pricing 


.section-pricing{
	background: url("../images/banner/speakers-bg-black.jpg")no-repeat;
	background-size: cover;
}
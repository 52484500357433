.sponsors {
	position: relative;
	background: url("../images/bg/cta-bg.jpg")no-repeat fixed 50% 50%;
	background-size: cover;

	&:before {
		position: absolute;
		content: "";
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.97);
	}
}

.client-item {
	a {
		display: block;
	}

	float: left;
	width: 15%;
	height: 115px;
	border:1px solid rgba(255, 255, 255, 0.05);
	padding: 20px;
	text-align: center;
	opacity: .7;
	-webkit-transition: all .45s ease-in-out;
	-moz-transition: all .45s ease-in-out;
	-ms-transition: all .45s ease-in-out;
	-o-transition: all .45s ease-in-out;
	transition: all .45s ease-in-out;

	&:hover {
		opacity: 1;
		background: $black;
	}

	@include desktop {
		width: 20%;
	}

	@include tablet {
		width: 30%;
	}

	@include mobile {
		width: 40%;
	}

	@include mobile-xs {
		width: 50%;
	}
}

.bg-black {
	background: #000;
}

.letter-spacing {
	letter-spacing: 2px;
	text-transform: uppercase;
}

.text-sm {
	font-size: 14px;
}

.bg-gray {
	background: #eee;
}
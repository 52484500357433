
//fonts

$base-font                : 'Poppins', sans-serif;
$heading-font             : 'Poppins', sans-serif;
$base-font-size           : 14px;

//colors

$white                    : #ffffff;
$black                    : #111111;
$black-lighter            : #444444;
$gray                    : #a4a4a4;
$gray-light               : #f8f8f8;
$border-color             : #e8e8e8;

$primary-color: #ff007a;
$secondary-color: #4125dd;
$orange:#ff4747;
$yellow:#f87c57;






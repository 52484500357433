// Navigation Styles
.navbar-brand {
  font-size: 28px;
  font-weight: 700;
  color: $secondary-color;
}

.main-nav {
  transition: all .7s linear;

  .nav-link {
    font-size: 14px;
    padding: 26px 18px !important;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $black;
    opacity: .9;

  }
}

.btn-sm {
  padding: 5px 18px;
  border-radius: 50px;
}

@media (min-width: 768px) {
  .top-nav-collapse {
    text-shadow: none;
    border-color: transparent;
    background-color: $white;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease;
  }
}

.dropdown {
  padding: 0px;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  padding: 10px 12px 10px 25px;
  border-bottom: 1px dashed $border-color;
  border-left: 3px solid transparent;

  &:hover {
    color: $primary-color;
    background: transparent;
    border-left: 3px solid $primary-color;
  }
}

.dropdown-menu {
  min-width: 250px;
  border: 0px;
  border-radius: 0px;
  box-shadow: 3px 1px 20px 0 rgba(0, 0, 0, 0.07);
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  transition: all .15s ease-out;
  display: block;
  padding: 0px !important;
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

// page-header
// 
.page-header {
  ul li {
    color: $white;
    font-size: 18px;

    a {
      color: $white;
    }
  }

  .divider {
    width: 100px;
    height: 4px;
  }
}

.error {
  h2 {
    font-size: 89px;
    color: #888;
  }
}




@include mobile {
  .main-nav .nav-link {
    padding: 10px 18px !important;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
  }

}

@include mobile-xs {
  .main-nav .nav-link {
    padding: 10px 18px !important;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
  }

}


@include tablet {
  .main-nav .nav-link {
    padding: 10px 18px !important;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
  }

}

@include desktop {
  .main-nav .nav-link {
    padding: 10px 18px !important;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

#select-language{
  @include desktop {
    display: block;
    margin: auto !important;
  }
}